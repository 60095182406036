import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Dimensions, TouchableOpacity } from "react-native";
import { COLORS, FONTS } from "../../constants";
import { Settings } from "lucide-react";
import { useCart } from "../../context/SinglecartContext";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

function Layouts({ children }) {
  const [showsettings, setShowSettings] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  const { stateLayout, changeLayout, includeDeviceFrmae, deviceFrame } = useCart();

  const styles = StyleSheet.create({
    layouts: {
      flex: 1,
      justifyContent: stateLayout,
      alignItems: stateLayout,
      backgroundColor: COLORS.backgroundDark,
      position: "relative",
      width: wp(100),
      height: hp(100),
      overflow: "hidden",
    },
    webpacksettings: {
      position: "absolute",
      left: wp(10),
      top: hp(2),
      padding: 5,
      borderRadius: 10,
      zIndex: 100,
    },
    custome: {
      marginVertical: 5,
      marginTop: 10,
    },
    textCustomize: {
      ...FONTS.subsmalltext,
      fontSize: 14,
    },
    buttons: {
      paddingVertical: 5,
      paddingHorizontal: 10,
      backgroundColor: COLORS.backgroundLightest,
      borderRadius: 5,
      marginVertical: 5,
    },
  });

  // Const open menu
  const setMenuSetting = () => setShowSettings(!showsettings)

  useEffect(() => {
    const screenWidth = Dimensions.get('screen').width;
    const thresholdWidth = 768; 
    setIsLargeScreen(screenWidth >= thresholdWidth);
  }, [isLargeScreen]);

  return (
    <View style={styles.layouts}>
      <View style={[styles.webpacksettings, {left: stateLayout === "flex-start" ? wp(33) : 40}]}>
       {isLargeScreen &&
        <TouchableOpacity onPress={setMenuSetting}>
          <Settings color="white" size={18} style={{zIndex: 101, cursor: "pointer"}}/>
        </TouchableOpacity>
      }
        {showsettings && 
        <View style={styles.custome}>
          <Text style={[styles.textCustomize, { color: "orange" }]}>Layout Accessibility</Text>
          <View>
            <TouchableOpacity style={styles.buttons} onPress={() => changeLayout("flex-start")}>
              <Text style={styles.textCustomize}>Back to Default</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.buttons} onPress={() => changeLayout("center")}>
              <Text style={styles.textCustomize}>Center the Application</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.buttons} onPress={() => changeLayout("flex-end")}>
              <Text style={styles.textCustomize}>Access from the Right</Text>
            </TouchableOpacity>
          </View>
          <Text style={[styles.textCustomize, { color: "orange" }]}>Device Frame</Text>
          <View>
            <TouchableOpacity style={styles.buttons} onPress={() => includeDeviceFrmae()}>
              <Text style={styles.textCustomize}>{deviceFrame?"Remove Frame":"Add Frame"}</Text>
            </TouchableOpacity>
          </View>
        </View>
      }
      </View>
      <View style={{ borderWidth: deviceFrame && 8, borderColor: deviceFrame && COLORS.backgroundLightthree, height: hp(100), width: wp(100), maxWidth: 430, borderRadius: 10, overflow: "hidden",
    }}>
    {children}
    </View>
    </View>
  );
}

export default Layouts;