import { COLORS } from "../constants";
import axios from "axios";
import { InterswitchPay } from 'react-interswitch';

export const HandleTransactions = (profile) => {
    const namesplit = profile.customer_name.split(" ")
    console.log('customer info ',namesplit);

    let configuration = {
        dismissButtonStyle: "cancel",
        preferredBarTintColor: COLORS.blue,
        preferredControlTintColor: "white",
        readerMode: false,
        animated: true,
        modalPresentationStyle: "popover",
        modalTransitionStyle: "coverVertical",
        modalEnabled: true,
        enableBarCollapsing: false,
        showTitle: true,
        toolbarColor: COLORS.blue,
        secondaryToolbarColor: "black",
        navigationBarColor: "black",
        navigationBarDividerColor: "white",
        enableUrlBarHiding: true,
        enableDefaultShare: true,
        forceCloseOnRedirection: true,
        forceCloseOnRedirection: true,
        forceCloseOnRedirection: true,
        animations: {
          startEnter: "slide_in_right",
          startExit: "slide_out_left",
          endEnter: "slide_in_left",
          endExit: "slide_out_right",
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        navigation: "yes",
        hideToolbar: "no",
      };
    
      const options = {
        transactionReference: `ROUTE_TZD_${(Math.random() + 1)
          .toString(36)
          .substring(9)}`.toUpperCase(),
        merchantCode: "ROUTEK0001",
        currencyCode: "KES",
        amount: Number(profile.total_bill) * 100,
        orderId: profile?.wallet_account?.wallet_account,
        terminalType: "WEB",
        redirectUrl: "#",
        domain: "ISWKE",
        customerId: profile.id,
        customerFirstName: namesplit[0],
        customerSecondName: namesplit[1],
        customerEmail: profile.email,
        customerMobile: profile.customer_phone,
        customerCity: "Nairobi",
        customerCountry: "KE",
        customerState: "NBI",
      };
      
    const loadMoneyTwo = () => {
        axios
            .post(
              "https://gatewaybackend.quickteller.co.ke/ipg-backend/api/checkout",
            options,
            {
              headers: {  
                "Content-Type": "application/x-www-form-urlencoded",
                'Access-Control-Allow-origin' : 'https://gatewaybackend.quickteller.co.ke',
              },
            },
            )
            .then(response => response.request.responseURL)

            .then(url => {
            console.log(url);
            return InAppBrowser.open(url, configuration);
            })
            .catch(error => console.error(error));
        return;
    };


    return profile.total_bill > 1 && loadMoneyTwo()
}


// import React, { useState } from 'react';
// import { COLORS } from "../constants";
// import { InterswitchPay } from 'react-interswitch';

// export const HandleTransactions = ({ profile }) => {
//   const [paymentInfo, setPaymentInfo] = useState({
//     customerEmail: profile.email,
//     customerName: profile.customer_name,
//     amount: Number(profile.total_bill) * 100,
//   });

//   const namesplit = paymentInfo.customerName.split(" ");

//   const handlePaymentSuccess = (response) => {
//     console.log('Payment successful:', response);
//     // Handle success actions here (e.g., redirect to a success page or show a success message)
//   };

//   const handlePaymentClose = () => {
//     console.log('Payment window closed');
//   };

//   const options = {
//     transactionReference: `ROUTE_TZD_${(Math.random() + 1).toString(36).substring(9)}`.toUpperCase(),
//     merchantCode: "ROUTEK0001",
//     currencyCode: "KES",
//     amount: paymentInfo.amount,
//     orderId: profile?.wallet_account?.wallet_account,
//     terminalType: "WEB",
//     redirectUrl: "#",
//     domain: "ISWKE",
//     customerId: profile.id,
//     customerFirstName: namesplit[0],
//     customerSecondName: namesplit[1],
//     customerEmail: paymentInfo.customerEmail,
//     customerMobile: profile.customer_phone,
//     customerCity: "Nairobi",
//     customerCountry: "KE",
//     customerState: "NBI",
//   };

//   return (
//     <div>
//       <h1>Quickteller Payment</h1>
//       <form>
//         <div>
//           <label>Email:</label>
//           <input
//             type="email"
//             name="customerEmail"
//             value={paymentInfo.customerEmail}
//             onChange={(e) => setPaymentInfo({ ...paymentInfo, customerEmail: e.target.value })}
//             required
//           />
//         </div>
//         <div>
//           <label>Name:</label>
//           <input
//             type="text"
//             name="customerName"
//             value={paymentInfo.customerName}
//             onChange={(e) => setPaymentInfo({ ...paymentInfo, customerName: e.target.value })}
//             required
//           />
//         </div>
//         <div>
//           <label>Amount:</label>
//           <input
//             type="number"
//             name="amount"
//             value={paymentInfo.amount / 100}
//             onChange={(e) => setPaymentInfo({ ...paymentInfo, amount: e.target.value * 100 })}
//             required
//           />
//         </div>
//       </form>
//       {profile.total_bill > 1 && (
//         <InterswitchPay
//           style={{}}
//           className="pay-button"
//           callback={handlePaymentSuccess}
//           close={handlePaymentClose}
//           disabled={false}
//           text="Pay with Quickteller"
//           embed={true} // Embed the payment form
//           payItemID="YOUR_QUICKTELLER_PAY_ITEM_ID" // Replace with your actual pay item ID
//           customerEmail={options.customerEmail}
//           customerName={options.customerFirstName + ' ' + options.customerSecondName}
//           redirectURL="http://example.com/success" // Use your success URL
//           mode="TEST" // Change to 'LIVE' for production
//           transactionReference={options.transactionReference}
//           amount={options.amount}
//           currencyCode={options.currencyCode}
//           domain={options.domain}
//           customerId={options.customerId}
//           customerFirstName={options.customerFirstName}
//           customerSecondName={options.customerSecondName}
//           customerMobile={options.customerMobile}
//           customerCity={options.customerCity}
//           customerCountry={options.customerCountry}
//           customerState={options.customerState}
//           merchantCode={options.merchantCode}
//         />
//       )}
//     </div>
//   );
// };

// export default HandleTransactions;
